import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/frontpage.scss"

const Home = ({ data }) => (
  <Layout seoMetaTags={data?.datoCmsFrontpage?.seoMetaTags} page="Etusivu">
    <div className="frontpage">
      <div className="textBlock1">
        <div
          dangerouslySetInnerHTML={{
            __html:
              data?.datoCmsFrontpage?.textBlock1Node.childMarkdownRemark.html,
          }}
          className="content"
        />
      </div>

      <div className="sponsors">
        <div className="content">
          <h2>Pääyhteistyökumppanit</h2>
          <div className="logos">
            {data?.datoCmsFrontpage?.pYhteystyKumppanit.map((partner, i) => (
              <a href={partner.url} key={i}>
                <img
                  className="logo"
                  alt={partner.company}
                  src={partner.logo.url}
                />
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="sponsors">
        <div className="content">
          <h2>Asiantuntijakumppanit</h2>
          <div className="logos">
            {data?.datoCmsFrontpage?.asiantuntijakumppanit.map(partner => (
              <a href={partner.url} key={partner.url}>
                <img
                  className="logo"
                  alt={partner.company}
                  src={partner.logo.url}
                />
              </a>
            ))}
          </div>
        </div>
      </div>

      <div
        className="textBlock2 content"
        dangerouslySetInnerHTML={{
          __html:
            data?.datoCmsFrontpage?.textBlock2Node.childMarkdownRemark.html,
        }}
      />

      <div
        className="textBlock3 content"
        dangerouslySetInnerHTML={{
          __html:
            data?.datoCmsFrontpage?.textBlock3Node.childMarkdownRemark.html,
        }}
      />
    </div>
  </Layout>
)
export const query = graphql`
  query frontPageQuery {
    datoCmsFrontpage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      textBlock1Node {
        childMarkdownRemark {
          html
        }
      }
      textBlock2Node {
        childMarkdownRemark {
          html
        }
      }
      pYhteystyKumppanit {
        url
        company
        logo {
          url
        }
      }
      textBlock3Node {
        childMarkdownRemark {
          html
        }
      }
      asiantuntijakumppanit {
        url
        company
        logo {
          url
        }
      }
    }
  }
  fragment personFields on DatoCmsPerson {
    image {
      alt
      fluid(maxWidth: 200) {
        ...GatsbyDatoCmsFluid
      }
    }
    surname
    firstname
    company
    title
    descriptionNode {
      childMarkdownRemark {
        html
      }
    }
    imageCopyright
  }
`

export default Home
